<template>
  <div class="layout-overlay">
    <slot />
    <Transition name="slide-down">
      <AppAlertBox v-if="isAlertBoxOpen" />
    </Transition>

    <Transition name="fade-in">
      <AppConfirmPopup v-if="isPopupOpen" />
    </Transition>
  </div>
</template>

<script setup>

/**
 * Initialize Popup
 */
const popupStore = useConfirmPopupStore();
const isPopupOpen = computed(() => popupStore.getRenderState);

/*
  Initialize Alertbox
*/
const alertBoxStore = useAlertBoxStore();
const isAlertBoxOpen = computed(() => alertBoxStore.isOpen);
</script>

<style lang="scss">
.layout-overlay {
    width: 100%;
    min-height: 100vh;
}
</style>
